{
  "mobile": {
    "landscape": 800,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1200,
    "normal": 1400
  }
}